<template lang="pug">
td
  .indicator(:class="{ succeeded: user.user_active }")
td {{ dateTransformed }}
td {{ user.user_email }}
td {{ user.user_country }}/{{ user.user_language }}
td
  routerLink(:to="{ name: 'ProfileUser', params: { user_id: user._id } }")
    .ml-auto.flex.justify-center.items-center.w-40.bg-blue-700.rounded-md.py-1.cursor-pointer
      svgIcon.mr-2.-ml-2(type="mdi", :path="mdiAccount", :size="18")
      .text-sm Show profile
</template>
<script>
import { mdiAccount } from "@mdi/js";

import { toRefs } from "vue";
import dateTransformer from "@/composables/dateTransformer";

export default {
  name: "rowListUsers",
  props: {
    user: Object,
  },
  data: () => ({
    mdiAccount,
  }),

  setup(props) {
    const { user } = toRefs(props);
    const { dateTransformed } = dateTransformer(user.value.created_at);
    return {
      dateTransformed,
    };
  },
};
</script>
<style lang="scss" scoped>
</style>