<template lang="pug">
.container
  baseHeading List Users
  .text-sm.-mt-4 Total results: <b>{{ total_results }}</b>
  .flex.justify-end.mb-4
    baseInputText(:iconPath="mdiSearchWeb", v-model="input_email")
    baseButton.ml-2(@click="findEmail") Search
  .border.rounded-md.mb-4
    table.table-auto.w-full.text-left
      thead.border-b
        tr
          th.w-6
          th Date
          th Email
          th Country/Lang
          th
      tbody
        tr(v-for="item in list_users", :key="item._id")
          rowListUser(:user="item")
  .flex.justify-end
    .pagination
      ul
        li(v-for="pagination_page in pagination", :key="pagination_page.page")
          routerLink(
            :to="pagination_page.route",
            :class="{ active: pagination_page.page == page }"
          ) {{ pagination_page.page }}
</template>
<script>
import UsersRepository from "@/api/users.repository";
import rowListUser from "@/components/users/rowListUsers";

import { mdiSearchWeb } from "@mdi/js";
export default {
  name: "ListUsers",
  components: {
    rowListUser,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 100,
    },
    query: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    mdiSearchWeb,
    total_pages: 0,
    total_results: 0,
    list_users: [],
    input_email: null,
  }),
  created() {
    this.getUsers(this.limit, this.page, this.query);
  },
  watch: {
    page() {
      this.getUsers(this.limit, this.page, this.query);
    },
    limit() {
      this.getUsers(this.limit, this.page, this.query);
    },
    query() {
      this.getUsers(this.limit, this.page, this.query);
    },
  },
  methods: {
    findEmail() {
      this.$router.push({ to: "/users", query: { query: this.input_email } });
    },
    getUsers(limit, page, query) {
      UsersRepository.listUsers(limit, page, query).then(({ data }) => {
        this.total_pages = data.total_pages;
        this.list_users = data.list_users;
        this.total_results = data.total_results;
      });
    },
  },
  computed: {
    pagination() {
      const pages = [];

      if (this.page >= 1 && this.total_pages >= 1) {
        for (let index = 3; index > 0; index--) {
          if (this.page - index > 1) {
            pages.push({
              page: this.page - index,
              route: `/users?page=${this.page - index}&limit=${this.limit}${
                this.query ? "&query=" + this.query : ""
              }`,
            });
          }
        }
      }

      if (this.page >= 1 && this.total_pages >= 1) {
        for (let index = 0; index < 5; index++) {
          if (this.page + index <= this.total_pages) {
            pages.push({
              page: this.page + index,
              route: `/users?page=${this.page + index}&limit=${this.limit}${
                this.query ? "&query=" + this.query : ""
              }`,
            });
          }
        }
      }

      if (pages[0]?.page > 1) {
        pages.unshift({
          page: 1,
          route: `/users?page=1&limit=${this.limit}${
            this.query ? "&query=" + this.query : ""
          }`,
        });
      }
      return pages;
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  thead {
    th {
      @apply p-2;
    }
  }
  :deep()tbody {
    td {
      @apply p-2;
    }
    tr:nth-child(odd) {
      background-color: rgb(36, 45, 59);
    }
  }
}

.pagination ul {
  margin: 0;
  padding-left: 0;
}

.pagination li {
  list-style: none;
  float: left;

  & + li {
    margin-left: 4px;
  }
}

.pagination a {
  border: 1px solid transparent;
  float: left;
  position: relative;
  text-decoration: none;
  @apply w-8 h-8 flex items-center justify-center rounded-md font-semibold text-gray-400 bg-gray-600 cursor-pointer;
}

.pagination a:hover {
  @apply bg-gray-500;
}

.pagination a.active,
.pagination a.active:focus,
.pagination a.active:hover {
  @apply bg-gray-500 text-white;
  cursor: default;
}

.pagination .ellipsis a,
.pagination .disabled a,
.pagination .disabled a:focus,
.pagination .disabled a:hover {
  cursor: not-allowed;
}

.pagination .disabled a,
.pagination .disabled a:focus,
.pagination .disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}
</style>